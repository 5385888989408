<template>
  <Popup ref="popupRef" width="500px" title="企业信息" :showConfirm="false" :showCancel="false">
    <div class="detail" v-if="entDetail">
      <div class="ent-row">
        <div class="label">企业名称：</div>
        <span>{{entDetail.entName}}</span>
      </div>
      <div class="ent-row">
        <div class="label">经营状态：</div>
        <span>{{entDetail.regStatus}}</span>
      </div>
      <div class="ent-row">
        <div class="label">成立时间：</div>
        <span>{{entDetail.estTime}}</span>
      </div>
      <div class="ent-row">
        <div class="label">注册地址：</div>
        <span>{{entDetail.regLoc}}</span>
      </div>
      <div class="ent-row">
        <div class="label">统一信用代码：</div>
        <span>{{entDetail.creditCode}}</span>
      </div>
      <div class="ent-row">
        <div class="label">组织机构代码：</div>
        <span>{{entDetail.orgNum}}</span>
      </div>
      <div class="ent-row">
        <div class="label">经营范围：</div>
        <span>{{entDetail.bizScope}}</span>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { enterpriseInfoApi } from '@/api/enterprise.js'
export default {
  components: {
    Popup
  },
  data(){
    return {
      entDetail: null,
      entId: ''
    }
  },
  methods: {
    open(option) {
      console.log('option',option)
      if(option.entId) {
        this.entId = option.entId
        this.getEnterpriseInfo()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    getEnterpriseInfo() {
      enterpriseInfoApi({
        entId: this.entId
      }).then(res => {
        console.log('企业工商信息', res)
        this.entDetail = res.data
        this.$refs.popupRef.open()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.detail {
  line-height: 30px;
  .ent-row {
    display: flex;
    .label {
      width: 100px;
      text-align: right;
    }
    span {
      flex: 1;
    }
  }
  .title-row {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 16px 0 8px;
  }
}
</style>